<template>
  <main class="">
    <b-container class="py-5 px-5 px-lg-8">
      <header class="my-5 pt-5">
        <h1 class="text-heading text-24 text-lg-32">
          &lt;(주)로스트컴퍼니&gt; 개인정보 처리방침
        </h1>
        <p class="mt-3 text-14 text-lg-16 fw-400 w-lg-50 lh-165">
          (주)로스트컴퍼니(이하 “회사”라 합니다)는 개인정보 보호법 제30조에 따라
          정보주체(고객)의 개인정보를 보호하고 이와 관련한 고충을 신속하고
          원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리지침을
          수립∙공개합니다.
        </p>
      </header>
      <ol class="px-4 mb-5">
        <li
          class="mt-5 pt-2 text-lg-20 fw-700"
          v-for="(item, i) in policy"
          :key="i"
        >
          {{ item.title }}
          <ul class="pt-2 list-unstyled pl-0 ml-n3">
            <li
              class="mt-2 text-14 text-lg-16 fw-400"
              v-for="(item2, l) in item.list"
              :key="l"
            >
              {{ item2 }}
            </li>
          </ul>
        </li>
      </ol>
    </b-container>
  </main>
</template>

<script>
import policy from "@/assets/policy.js";
export default {
  data() {
    return {
      policy: policy,
    };
  },
};
</script>

<style lang="scss" scoped>
@media all and (min-width: 1300px) {
  .px-lg-8 {
    padding-left: 8% !important;
    padding-right: 8% !important;
  }
}
</style>
