var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"home"},[_c('article',{attrs:{"id":"0"}},[_c('section',{staticClass:"vh-100 px-0 position-relative"},[_c('div',{staticClass:"position-absolute shadow-spread",style:({
          width: '78%',
          maxWidth: '1696px',
          height: '77%',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#161616',
          backgroundImage: ("url(" + (require('@/assets/bg-intro.png')) + ")"),
          backgroundPosition: 'right bottom',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        })},[_c('b-container',{staticClass:"h-100 p-5"},[_c('b-row',{staticClass:"h-100",attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"text-white h-100",attrs:{"cols":"","md":"8","lg":"6"}},[_c('section',{staticClass:"w-100 h-100 mt-1 mt-md-0 pt-md-5"},[_c('div',{staticClass:"d-none d-lg-flex align-items-end my-5"},[_c('div',{staticClass:"vl",style:({
                      width: '3px',
                      height: '6rem',
                      background: 'white',
                    })}),_c('div',{staticClass:"hl ml-4",style:({
                      width: '6rem',
                      height: '3px',
                      background: 'white',
                    })})]),_c('h1',{staticClass:"text-heading text-white text-36 text-lg-80 highlight highlight-primary text-nowrap"},[_vm._v(" 스쿼시를 위한"),_c('br'),_vm._v(" 모든 데이터 ")]),_c('h2',{staticClass:"text-white text-20 text-lg-32"},[_vm._v(" 스포츠 데이터 플랫폼, "),_c('br',{staticClass:"d-block d-md-none"}),_vm._v(" 로스트 ")]),_c('div',{staticClass:"mt-4 mt-md-5 d-md-flex align-items-start position-relative"},[_c('b-btn',{staticClass:"p-0 mr-3",attrs:{"variant":"transparant"}},[_c('img',{staticClass:"w-100",style:({ maxWidth: '140px' }),attrs:{"src":require('@/assets/appstore.png'),"alt":""}})]),_c('b-btn',{staticClass:"p-0",attrs:{"variant":"transparant"}},[_c('img',{staticClass:"w-100",style:({ maxWidth: '140px' }),attrs:{"src":require('@/assets/googleplay.png'),"alt":""}})]),_c('img',{staticClass:"position-absolute pointer-events-none d-none d-md-block",style:({
                      bottom: '-10rem',
                      left: '-3rem',
                      zIndex: '-1',
                    }),attrs:{"src":require('@/assets/balls/ball-intro-1.png'),"alt":""}}),_c('img',{staticClass:"position-absolute pointer-events-none d-md-none d-block",style:({
                      bottom: '-10rem',
                      left: '-50%',
                      zIndex: '-1',
                    }),attrs:{"src":require('@/assets/balls/ball-intro-1.png'),"alt":""}}),_c('img',{staticClass:"position-absolute pointer-events-none d-none d-md-block",style:({ bottom: '1rem', right: '15%', zIndex: '-1' }),attrs:{"src":require('@/assets/balls/ball-intro-2.png'),"alt":""}}),_c('img',{staticClass:"position-absolute pointer-events-none d-md-none d-block",style:({ top: '-40%', right: '-30%', zIndex: '-1' }),attrs:{"src":require('@/assets/balls/ball-intro-2.png'),"alt":""}})],1)])])],1)],1)],1),_c('div',{staticClass:"w-100 bg-primary",style:({ height: '40%' })}),_c('div',{staticClass:"w-100",style:({ height: '60%', backgroundColor: '#0E0D0E' })}),_c('div',{staticClass:"text-center position-absolute",style:({
          bottom: '2%',
          left: '50%',
          transform: 'translateX(-50%)',
        })},[_c('i',{staticClass:"icon icon-scroll-down icon-sm-30 icon-48"})])])]),_c('article',{staticClass:"vh-100 position-relative overflow-hidden",style:({ backgroundColor: '#E9EAF3' }),attrs:{"id":"1"}},[_c('b-container',{staticClass:"h-100 text-darkest p-4 p-md-0"},[_c('b-row',{staticClass:"h-100",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"","md":"6"}},[_c('div',{staticClass:"hl mb-5 d-none d-md-block",style:({
              width: '6rem',
              height: '3px',
              background: '#161616',
            })}),_c('h1',{staticClass:"text-heading text-36 text-lg-80 highlight w-50 highlight-light text-nowrap"},[_vm._v(" SYSTEM ")]),_c('small',{staticClass:"text-14 text-lg-20"},[_vm._v(" 로스트는 토너먼트를 위한 모든 기능을 제공합니다. ")]),_c('h2',{staticClass:"mt-3 mt-md-5 text-24 text-lg-50 fw-300"},[_vm._v(" 대회신청, 실시간 경기확인, "),_c('br'),_vm._v(" 랭킹관리 까지 ")]),_c('section',{staticClass:"mt-4 d-md-flex"},_vm._l((_vm.tabs),function(tab,i){return _c('b-btn',{key:i,staticClass:"mr-3 p-0",attrs:{"variant":"text-black"},on:{"click":function($event){_vm.tabIndex = i}}},[_c('span',{staticClass:"fw-700 text-14 text-lg-24",class:_vm.tabIndex == i ? 'text-primary' : '',style:(_vm.tabIndex == i ? { borderBottom: '2px solid #4e58e2' } : {})},[_vm._v(" "+_vm._s(tab.name))])])}),1),_c('div',{staticClass:"vl my-5 d-none d-md-block",style:({
              width: '3px',
              height: '6rem',
              background: '#161616',
            })})]),_c('b-col',{staticClass:"position-relative ",attrs:{"cols":"","md":"6"}},[_c('b-tabs',{attrs:{"nav-wrapper-class":"d-none"},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},_vm._l((_vm.tabs),function(tab,i){return _c('b-tab',{key:i},[_c('figure',{staticClass:"h-100 bg-img ratio-110",style:({
                  backgroundImage: ("url(" + (tab.image) + ")"),
                  backgroundSize: 'contain',
                })})])}),1),_c('img',{staticClass:"position-absolute pointer-events-none",style:({ bottom: '30%', left: '-4rem', zIndex: '1' }),attrs:{"src":require('@/assets/balls/ball-system-2.png'),"alt":""}})],1)],1)],1),_c('img',{staticClass:"position-absolute pointer-events-none",style:({ top: '10%', right: '2rem', zIndex: '1', width: '15%' }),attrs:{"src":require('@/assets/balls/ball-system-1.png'),"alt":""}})],1),_c('article',{staticClass:"vh-100 position-relative",style:({
      backgroundImage: ("url(" + (require('@/assets/bg-tournament.png')) + ")"),
      backgroundPosition: 'top right',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    }),attrs:{"id":"2"}},[_c('b-container',{staticClass:"h-100 text-darkest position-relative"},[_c('b-row',{staticClass:"h-100",attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"px-5 pt-5 px-md-2 pt-md-0",attrs:{"md":"5"}},[_c('img',{staticClass:"position-absolute pointer-events-none  d-none d-md-block",style:({ top: '-10%', right: '-10%', zIndex: '1' }),attrs:{"src":require('@/assets/balls/ball-tournament-2.png'),"alt":""}}),_c('div',{staticClass:"vl d-none d-md-block",style:({
              width: '3px',
              height: '6rem',
              background: '#161616',
            })}),_c('h1',{staticClass:"mt-5 w-75 highlight highlight-light text-heading text-40 text-lg-80 text-nowrap"},[_vm._v(" 손끝에서 시작되는 "),_c('br'),_vm._v(" 스쿼시의 미래 ")]),_c('h2',{staticClass:"mt-4 pb-4 text-20 text-lg-36 fw-400"},[_vm._v(" 토너먼트에서 직접 경험하세요 ")]),_c('img',{staticClass:"position-absolute pointer-events-none d-none d-md-block",style:({ bottom: '20%', left: '10%', zIndex: '1' }),attrs:{"src":require('@/assets/balls/ball-tournament-3.png'),"alt":""}}),_c('img',{staticClass:"position-absolute pointer-events-none d-md-none d-block",style:({ bottom: '-40%', left: '0', zIndex: '1', width: '30%' }),attrs:{"src":require('@/assets/balls/ball-tournament-3.png'),"alt":""}}),_c('div',{staticClass:"hl mt-5 mb-md-5",style:({
              width: '6rem',
              height: '3px',
              background: '#161616',
            })}),_c('div',{staticClass:"d-none d-md-block",style:({ height: '15rem' })})]),_c('b-col',{attrs:{"md":"7"}},[_c('figure',{staticClass:"bg-img w-100 ratio-90 d-none d-md-block",style:({
              backgroundImage: ("url(" + (require('@/assets/tournament.png')) + ")"),
              backgroundSize: 'cover',
            })}),_c('figure',{staticClass:"bg-img w-100 ratio-95 ml-n5 d-block d-md-none",style:({
              backgroundImage: ("url(" + (require('@/assets/tournament.png')) + ")"),
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
            })})])],1),_c('img',{staticClass:"position-absolute pointer-events-none",style:({ top: '10%', right: '10%', zIndex: '1', width: '15%' }),attrs:{"src":require('@/assets/balls/ball-tournament-1.png'),"alt":""}})],1)],1),_c('article',{staticClass:"vh-100 d-flex flex-column align-items-center justify-content-center",style:({
      backgroundImage: ("url(" + (require('@/assets/bg-vision.png')) + ")"),
      backgroundPosition: 'top left',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    }),attrs:{"id":"3"}},[_c('b-container',{staticClass:"d-none d-md-block"},[_c('b-row',{attrs:{"align-h":"center"}},[_c('b-col',{attrs:{"md":"6"}},[_c('figure',{staticClass:"card bg-img w-100 ratio-60",style:({
              backgroundImage: ("url(" + (require('@/assets/vision/vision-1.png')) + ")"),
            })},[_c('figcaption',{staticClass:"position-absolute d-flex align-items-center justify-content-between w-100 px-3 px-md-4 pb-3 pb-md-5",style:({ bottom: '0' })},[_c('div',{},[_c('h1',{staticClass:"text-heading mb-0 text-36 text-lg-64 text-white"},[_vm._v(" Route 17 ")]),_c('small',{staticClass:"fw-500 text-14 text-white text-truncate w-100 d-block"},[_vm._v(" 스포츠 맵 그 이상의 가치 ")])]),_c('div',{},[_c('b-btn',{staticClass:"rounded-circle",style:({ width: '5rem', height: '5rem' }),attrs:{"variant":"secondary"}},[_c('i',{staticClass:"icon icon-36 icon-arr-right"})])],1)])])]),_c('b-col',{attrs:{"md":"6"}},[_c('figure',{staticClass:"card bg-img w-100 ratio-60",style:({
              backgroundImage: ("url(" + (require('@/assets/vision/vision-2.png')) + ")"),
            })},[_c('figcaption',{staticClass:"position-absolute d-flex align-items-center justify-content-between w-100 px-3 px-md-4 pb-3 pb-md-5",style:({ bottom: '0' })},[_c('div',{},[_c('h1',{staticClass:"text-heading mb-0 text-36 text-lg-64 text-white"},[_vm._v(" R:Inven ")]),_c('small',{staticClass:"fw-500 text-14 text-white text-truncate w-100 d-block"},[_vm._v(" 사용자 중심의 신개념 용품관리 ")])]),_c('div',{},[_c('b-btn',{staticClass:"rounded-circle",style:({ width: '5rem', height: '5rem' }),attrs:{"variant":"secondary"}},[_c('i',{staticClass:"icon icon-36 icon-arr-right"})])],1)])])])],1),_c('b-row',{attrs:{"align-h":"center"}},[_c('b-col',{attrs:{"md":"3"}},[_c('figure',{staticClass:"card bg-img w-100 ratio-125 d-none d-md-block",style:({
              backgroundImage: ("url(" + (require('@/assets/vision/vision-3.png')) + ")"),
            })},[_c('b-btn',{staticClass:"position-absolute rounded-circle",style:({
                width: '5rem',
                height: '5rem',
                right: '8%',
                top: '8%',
              }),attrs:{"variant":"third"}},[_c('i',{staticClass:"icon icon-36 icon-arr-right"})]),_c('figcaption',{staticClass:"position-absolute d-flex align-items-center justify-content-between w-100 px-3 px-md-4 pb-3 pb-md-5",style:({ bottom: '0' })},[_c('div',{},[_c('h1',{staticClass:"text-heading mb-0 text-36 text-lg-40 text-white"},[_vm._v(" ROSTv ")]),_c('small',{staticClass:"fw-500 text-14 text-white text-truncate w-100 d-block"},[_vm._v(" 토너먼트 중계의 새로운 기준 ")])])])],1),_c('figure',{staticClass:"card bg-img w-100 ratio-120 d-block d-md-none",style:({
              backgroundImage: ("url(" + (require('@/assets/vision/vision-3.png')) + ")"),
            })},[_c('figcaption',{staticClass:"position-absolute d-flex align-items-center justify-content-between w-100 px-3 px-md-4 pb-3 pb-md-5",style:({ bottom: '0' })},[_c('div',{},[_c('h1',{staticClass:"text-heading mb-0 text-36 text-lg-40 text-white"},[_vm._v(" ROSTv ")]),_c('small',{staticClass:"fw-500 text-14 text-white text-truncate w-100 d-block"},[_vm._v(" 토너먼트 중계의 새로운 기준 ")])])])])]),_c('b-col',{attrs:{"md":"3"}},[_c('figure',{staticClass:"card bg-img w-100 ratio-125 d-none d-md-block",style:({
              backgroundImage: ("url(" + (require('@/assets/vision/vision-4.png')) + ")"),
            })},[_c('b-btn',{staticClass:"position-absolute rounded-circle",style:({
                width: '5rem',
                height: '5rem',
                right: '8%',
                top: '8%',
              }),attrs:{"variant":"third"}},[_c('i',{staticClass:"icon icon-36 icon-arr-right"})]),_c('figcaption',{staticClass:"position-absolute d-flex align-items-center justify-content-between w-100 px-3 px-md-4 pb-3 pb-md-5",style:({ bottom: '0' })},[_c('div',{},[_c('h1',{staticClass:"text-heading mb-0 text-36 text-lg-40 text-white"},[_vm._v(" Rally ")]),_c('small',{staticClass:"fw-500 text-14 text-white text-truncate w-100 d-block"},[_vm._v(" 대회에 참여하는 새로운 방법 ")])])])],1),_c('figure',{staticClass:"card bg-img w-100 ratio-120 d-block d-md-none",style:({
              backgroundImage: ("url(" + (require('@/assets/vision/vision-4.png')) + ")"),
            })},[_c('figcaption',{staticClass:"position-absolute d-flex align-items-center justify-content-between w-100 px-3 px-md-4 pb-3 pb-md-5",style:({ bottom: '0' })},[_c('div',{},[_c('h1',{staticClass:"text-heading mb-0 text-36 text-lg-40 text-white"},[_vm._v(" Rally ")]),_c('small',{staticClass:"fw-500 text-14 text-white text-truncate w-100 d-block"},[_vm._v(" 대회에 참여하는 새로운 방법 ")])])])])]),_c('b-col',{attrs:{"md":"6"}},[_c('figure',{staticClass:"card bg-img w-100 ratio-60",style:({
              backgroundImage: ("url(" + (require('@/assets/vision/vision-5.png')) + ")"),
            })},[_c('figcaption',{staticClass:"position-absolute d-flex align-items-center justify-content-between w-100 px-3 px-md-4 pb-3 pb-md-5",style:({ bottom: '0' })},[_c('div',{},[_c('h1',{staticClass:"text-heading mb-0 text-36 text-lg-64 text-white"},[_vm._v(" R-Market ")]),_c('small',{staticClass:"fw-500 text-14 text-white text-truncate w-100 d-block"},[_vm._v(" 수수료 없는 네트워크 프리마켓 ")])])])])])],1)],1),_c('b-container',{staticClass:"d-block d-md-none"},[_c('carousel',{staticClass:"px-5",attrs:{"perPage":1,"paginationActiveColor":"#4e58e2","paginationColor":"#222","paginationPadding":6,"loop":true}},_vm._l((_vm.visions),function(item,i){return _c('carousel-slide',{key:i},[_c('h1',{staticClass:"mb-0 text-heading text-36 text-darkest"},[_vm._v(" "+_vm._s(item.heading)+" ")]),_c('small',{staticClass:"text-14 text-darkest text-truncate"},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('figure',{staticClass:"mt-3 h-100 bg-img ratio-165 position-relative",style:({
              backgroundImage: ("url(" + (item.image) + ")"),
              backgroundSize: 'cover',
            })},[(item.url !== null)?_c('b-btn',{staticClass:"rounded-circle position-absolute px-3 py-2",style:({
                bottom: '20%',
                right: '4%',
                zIndex: '10',
              }),attrs:{"variant":item.btnVariant}},[_c('i',{staticClass:"icon icon-36 icon-arr-right mt-1"})]):_vm._e()],1)])}),1)],1)],1),_c('article',{staticClass:"vh-100",style:({ backgroundColor: '#0E0D0E' }),attrs:{"id":"4"}},[_c('b-container',{staticClass:"h-100 position-relative"},[_c('b-row',{staticClass:"h-100",attrs:{"align-v":"center","align-h":"center"}},[_c('b-col',{staticClass:"position-relative mt-5 mt-md-0",attrs:{"cols":"11","md":"6"}},[_c('figure',{staticClass:"card-goal bg-img w-75 ratio-75 ml-auto mr-4 mb-0",style:({
              backgroundImage: ("url(" + (require('@/assets/goal.png')) + ")"),
            })}),_c('div',{staticClass:"card-border bg-img w-75 ratio-75 ml-auto"})]),_c('b-col',{staticClass:"mt-n5 mt-md-0",attrs:{"cols":"11","md":"6"}},[_c('h1',{staticClass:"text-heading text-white text-36 text-lg-80 w-75 highlight highlight-fourth text-nowrap"},[_vm._v(" GOAL OF ROST ")]),_c('p',{staticClass:"mt-3 mt-md-5 mb-md-5 pr-3 pr-md-0 text-14 text-lg-18 text-white lh-180"},[_vm._v(" 진보된 디지털, 모바일 환경에 맞춰 기존의 데이터 기록 방식을 개선하는 것."),_c('br'),_vm._v(" 스쿼시의 새로운 가치를 이끌어내 이제까지 발견하지 못했던 재미를 창출하는 것."),_c('br'),_vm._v(" 플랫폼을 활용하는 사용자 모두가 서로에게 실용적이고 유익한 데이터를 제공하는 것."),_c('br'),_c('br'),_vm._v(" 로스트는 스쿼시를 즐기는 새로운 기준을 제시합니다. ")])])],1),_c('img',{staticClass:"position-absolute pointer-events-none",style:({ top: '5%', right: '-10%', zIndex: '1', width: '20%' }),attrs:{"src":require('@/assets/balls/ball-goal-1.png'),"alt":""}}),_c('img',{staticClass:"position-absolute pointer-events-none",style:({ bottom: '8%', left: '-5%', zIndex: '1', width: '18%' }),attrs:{"src":require('@/assets/balls/ball-goal-2.png'),"alt":""}})],1)],1),_c('side-btns',{attrs:{"sections":_vm.sections,"active":_vm.active,"options":_vm.options}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }