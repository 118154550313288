<template>
  <main class="apply">
    <article id="0">
      <section class="vh-100 px-0 position-relative">
        <div
          class="position-absolute shadow-spread"
          :style="{
            width: '78%',
            maxWidth: '1696px',
            height: '77%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#161616',
          }"
        >
          <b-container
            class="h-100 mx-auto px-2 py-3 p-md-5 text-center position-relative"
          >
            <div
              class="d-none d-lg-flex justify-content-end align-items-start position-absolute"
              :style="{ right: '2%' }"
            >
              <div
                class="hl"
                :style="{
                  width: '10rem',
                  height: '3px',
                  background: 'white',
                }"
              ></div>
              <div
                class="vl"
                :style="{
                  width: '3px',
                  height: '13rem',
                  background: 'white',
                }"
              ></div>
            </div>
            <h1
              class="mt-4 mt-md-5 text-heading mx-auto text-white text-center w-lg-50 text-36 text-lg-80 highlight highlight-primary text-nowrap"
            >
              복잡한 대회운영,
            </h1>
            <h2 class="mb-0 text-white text-18 text-lg-32 fw-300">
              이제 ROST에서 <br class="d-block d-md-none" />
              쉽고 빠르게 해결하세요
            </h2>
            <section class="mt-4 mt-md-0 px-md-5 pt-md-5 d-none d-md-block">
              <b-row class="mt-md-5" align-h="center" align-v="center">
                <b-col
                  cols="6"
                  md="3"
                  v-for="(item, i) in apps"
                  :key="i"
                  class="mt-4 mt-md-0 d-flex flex-column align-content-center justify-content-center"
                >
                  <figure
                    class="border border-primary rounded-lg mx-auto p-3 p-md-5"
                  >
                    <div class="my-0 my-md-2 mx-2 mx-md-3">
                      <i
                        class="icon mt-1 icon-72 icon-sm-36"
                        :class="'icon-' + item.icon"
                      ></i>
                    </div>
                  </figure>
                  <figcaption
                    class="ml-lg-3 pl-lg-4 pt-md-2 text-cetner text-md-left"
                  >
                    <h4
                      class="mb-0 mb-lg-2 text-heading text-18 text-lg-24 text-white"
                    >
                      {{ item.title }}
                    </h4>
                    <p class="mt-2 mb-0 text-white text-12 text-lg-16 lh-160">
                      {{ item.desc }}
                    </p>
                  </figcaption>
                </b-col>
              </b-row>
            </section>
            <carousel
              class="d-block d-md-none m-5"
              :perPage="1"
              paginationActiveColor="#4e58e2"
              paginationColor="#222"
              :paginationPadding="4"
              :loop="true"
            >
              <carousel-slide class="px-3" v-for="(item, i) in apps" :key="i">
                <figure
                  class="border border-primary rounded-lg py-4 text-center"
                >
                  <i
                    class="icon mt-1 icon-72 icon-sm-56"
                    :class="'icon-' + item.icon"
                  ></i>
                </figure>
                <figcaption class="pt-2 text-cetner text-md-left">
                  <h4
                    class="mb-0 mb-lg-2 text-heading text-18 text-lg-24 text-white"
                  >
                    {{ item.title }}
                  </h4>
                  <p class="mt-2 mb-0 text-white text-14 lh-160">
                    {{ item.desc }}
                  </p>
                </figcaption>
              </carousel-slide>
            </carousel>
            <b-row align-h="between" align-v="end" class="mt-2 mt-md-5">
              <b-col cols="4" offset="2" class="d-none d-md-block">
                <div
                  class="hl"
                  :style="{
                    width: '100%',
                    height: '3px',
                    background: 'white',
                  }"
                ></div>
              </b-col>
              <b-col cols="12" md="2">
                <b-btn variant="secondary" class="ml-md-5 px-4 text-nowrap"
                  >OPERATOR MODE</b-btn
                >
              </b-col>
              <b-col cols="1" class="d-none d-md-block"></b-col>
            </b-row>
            <img
              :src="require('@/assets/balls/ball-system-1.png')"
              class="position-absolute pointer-events-none d-none d-md-block"
              :style="{ top: '10%', left: '2rem', zIndex: '1' }"
              alt=""
            />
            <img
              :src="require('@/assets/balls/ball-system-2.png')"
              class="position-absolute pointer-events-none d-none d-md-block"
              :style="{ bottom: '10%', right: '4rem', zIndex: '1' }"
              alt=""
            />
          </b-container>
        </div>
        <div class="w-100 bg-primary" :style="{ height: '40%' }"></div>
        <div
          class="w-100"
          :style="{ height: '60%', backgroundColor: '#0E0D0E' }"
        ></div>
        <div
          class="text-center position-absolute"
          :style="{
            bottom: '2%',
            left: '50%',
            transform: 'translateX(-50%)',
          }"
        >
          <i class="icon icon-scroll-down icon-sm-30 icon-48"></i>
        </div>
      </section>
    </article>
    <article
      id="1"
      class="w-100 vh-100 position-relative pt-5"
      :style="{
        backgroundImage: `url(${require('@/assets/bg-tournament.png')})`,
        backgroundPosition: 'top right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
      }"
    >
      <b-container class="h-100 text-darkest position-relative pr-4 pr-md-0">
        <header class="text-center mt-5 pt-5">
          <h1
            class="mx-auto text-heading text-darkest text-36 text-lg-80 highlight highlight-light w-lg-25 text-nowrap"
          >
            BEYOND
          </h1>
          <h1
            class="mx-auto text-heading text-darkest text-36 text-lg-80 text-nowrap"
          >
            TOURNAMENT
          </h1>
          <h2
            class="mt-5 pt-5 pt-md-5 pt-md-0 text-darkest text-20 text-lg-32 fw-300"
          >
            종이의 한계를 뛰어넘는 <br class="d-block d-md-none" />
            대회운영 시스템
          </h2>
        </header>
        <b-row class="w-100 mt-md-n4" align-h="center" align-v="start">
          <b-col cols="12" md="9" class="mt-md-n5">
            <figure
              class="bg-img ratio-65 w-100 d-none d-md-block position-relative"
              :style="{
                backgroundImage: `url(${require('@/assets/bg-byond.png')})`,
                backgroundSize: 'cover',
              }"
            >
              <figcaption
                class="position-absolute p-4 d-flex align-items-center"
                :style="{ left: '14%', bottom: '20%' }"
              >
                <div class="mr-3">
                  <b-btn
                    variant="secondary"
                    class="rounded-circle"
                    :style="{ width: '5rem', height: '5rem' }"
                  >
                    <i class="icon icon-36 icon-arr-right"></i>
                  </b-btn>
                </div>
                <div class="">
                  <h1 class="mb-0 text-36 text-lg-48 text-white fw-700">
                    대회 운영방법
                  </h1>
                  <small
                    class="fw-500 text-18 fw-500 d-block"
                    :style="{ color: '#999' }"
                  >
                    자세히 보기
                  </small>
                </div>
              </figcaption>
            </figure>
            <figure
              class="bg-img ratio-75 d-md-none d-block"
              :style="{
                width: '130%',
                marginLeft: '-15%',
                backgroundImage: `url(${require('@/assets/bg-byond.png')})`,
                backgroundSize: 'cover',
              }"
            ></figure>
            <figcaption
              class="mt-n4 d-flex align-items-center d-md-none d-block"
            >
              <div class="mr-3">
                <b-btn
                  variant="secondary"
                  class="rounded-circle"
                  :style="{ width: '4rem', height: '4rem' }"
                >
                  <i class="icon icon-28 mt-1 icon-arr-right"></i>
                </b-btn>
              </div>
              <div class="">
                <h1 class="mb-0 text-24 text-lg-48 text-darkest fw-700">
                  대회 운영방법
                </h1>
                <small
                  class="fw-500 text-18 fw-500 d-block"
                  :style="{ color: '#999' }"
                >
                  자세히 보기
                </small>
              </div>
            </figcaption>
          </b-col>
        </b-row>

        <img
          :src="require('@/assets/balls/ball-tournament-3.png')"
          class="position-absolute pointer-events-none d-none d-md-block"
          :style="{ bottom: '25%', left: '-3%', zIndex: '1' }"
          alt=""
        />
        <img
          :src="require('@/assets/balls/ball-tournament-3.png')"
          class="position-absolute pointer-events-none d-md-none d-block"
          :style="{ bottom: '15%', right: '25%', zIndex: '1', width: '30%' }"
          alt=""
        />
        <img
          :src="require('@/assets/balls/ball-tournament-1.png')"
          class="position-absolute pointer-events-none d-none d-md-block"
          :style="{ top: '10%', right: '10%', zIndex: '1', width: '15%' }"
          alt=""
        />
        <img
          :src="require('@/assets/balls/ball-tournament-1.png')"
          class="position-absolute pointer-events-none d-md-none d-block"
          :style="{ top: '18%', right: '10%', zIndex: '1', width: '25%' }"
          alt=""
        />
      </b-container>
    </article>
    <article id="2" class="vh-100 px-0 mx-0" :style="{ overlfow: 'visible' }">
      <b-container class="h-100 position-relative pt-md-5 px-0" fluid>
        <section class="h-100 pt-5 text-darkest position-relative pr-4 pr-md-0">
          <header class="text-center mt-5 pt-5">
            <h1
              class="pt-md-0 mx-auto text-heading text-darkest text-36 text-lg-80 highlight highlight-light w-lg-50 text-nowrap"
            >
              ALL-IN-ONE
            </h1>
            <h2
              class="mt-3 mt-md-5 pt-5 pt-md-0 text-darkest text-20 text-lg-32 fw-300"
            >
              스포츠를 위한 모든 데이터, <br />
              이제 로스트 플랫폼에서 만나보세요.
            </h2>
          </header>
          <div class="w-100 h-100 d-none d-md-block position-relative">
            <figure
              class="bg-img ratio-45 position-absolute pointer-events-none"
              :style="{
                backgroundImage: `url(${require('@/assets/allinone/tablet.png')})`,
                backgroundSize: 'contain',
                zIndex: '3',
                width: '860px',
                top: '36%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }"
            ></figure>
            <carousel-3d
              :display="5"
              :disable3d="true"
              :autoplay="true"
              class="w-100 h-100 position-absolute border-0"
              :width="840"
              :height="473"
              :style="{
                zIndex: '2',
                top: '60%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }"
            >
              <slide
                v-for="(item2, k) in allInOne"
                :index="k"
                :key="k"
                class="border-0 bg-transparent"
              >
                <img :src="item2" />
              </slide>
            </carousel-3d>
          </div>

          <div
            class="w-100 d-md-none d-block position-relative"
            :style="{ height: '160px' }"
          >
            <figure
              class="bg-img ratio-100 position-absolute pointer-events-none"
              :style="{
                backgroundImage: `url(${require('@/assets/allinone/tablet.png')})`,
                backgroundSize: 'contain',
                zIndex: '3',
                width: '100%',
                top: '80%',
                left: '40%',
                transform: 'translate(-50%, -50%)',
              }"
            ></figure>
            <carousel-3d
              :display="5"
              :disable3d="true"
              :autoplay="true"
              class="my-0 position-absolute border-0"
              :width="414"
              :style="{
                zIndex: '2',
                top: '90%',
                left: '38%',
                transform: 'translate(-50%, -50%)',
              }"
            >
              <slide
                v-for="(item2, k) in allInOne"
                :index="k"
                :key="k"
                class="border-0 bg-transparent"
              >
                <img :src="item2" />
              </slide>
            </carousel-3d>
          </div>
          <!-- <b-row>
            <b-col>
              <div
                class="position-relative d-md-none d-block ml-"
                :style="{
                  width: '155%',
                  marginLeft: '-48%',
                }"
              >
                <figure
                  class="bg-img ratio-65 w-100 position-relative pointer-events-none"
                  :style="{
                    backgroundImage: `url(${require('@/assets/allinone/tablet.png')})`,
                    backgroundSize: 'cover',
                    zIndex: '3',
                  }"
                ></figure>
                <carousel-3d
                  :display="5"
                  :disable3d="true"
                  :autoplay="true"
                  class="w-100 h-100 position-absolute d-md-none d-block border-0"
                  :width="410"
                  :height="234"
                  :style="{
                    zIndex: '2',
                    top: '60%',
                    left: ' 50%',
                    transform: 'translate(-50%, -50%)',
                  }"
                >
                  <slide
                    v-for="(item2, k) in allInOne"
                    :index="k"
                    :key="k"
                    class="border-0 bg-transparent"
                  >
                    <img :src="item2" />
                  </slide>
                </carousel-3d>
              </div>
            </b-col>
          </b-row> -->
        </section>
        <img
          :src="require('@/assets/balls/ball-tournament-2.png')"
          class="position-absolute pointer-events-none d-none d-md-block"
          :style="{ top: '10%', right: '0%', zIndex: '1' }"
          alt=""
        />
        <img
          :src="require('@/assets/balls/ball-tournament-2.png')"
          class="position-absolute pointer-events-none d-md-none d-block"
          :style="{ top: '3%', right: '0%', zIndex: '1' }"
          alt=""
        />
        <img
          :src="require('@/assets/balls/ball-tournament-3.png')"
          class="position-absolute pointer-events-none d-none d-md-block"
          :style="{ top: '25%', left: '5%', zIndex: '1' }"
          alt=""
        />
        <!-- <img
          :src="require('@/assets/balls/ball-tournament-3.png')"
          class="position-absolute pointer-events-none d-md-none d-block"
          :style="{ top: '-40%', left: '0', zIndex: '1', width: '30%' }"
          alt=""
        /> -->
      </b-container>
    </article>
    <article
      id="3"
      class="vh-100"
      :style="{
        backgroundColor: '#0E0D0E',
        backgroundImage: `url(${require('@/assets/bg-form.png')})`,
        backgroundPosition: 'left top',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }"
    >
      <section class="vh-100 px-0 position-relative">
        <div
          class="position-absolute shadow-spread"
          :style="{
            width: '78%',
            maxWidth: '1696px',
            top: '53%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#161616',
          }"
        >
          <b-container
            class="h-100 mx-auto px-2 py-3 p-md-5 text-center position-relative"
          >
            <h1
              class="mt-4 mt-md-5 text-heading mx-auto text-white text-center w-lg-50 text-32 text-lg-80 highlight highlight-primary text-nowrap"
            >
              더 나은 대회환경,
            </h1>
            <h2 class="mb-0 text-white text-18 text-lg-32 fw-300">
              이제 로스트와 함께 고민하세요
            </h2>
            <b-row>
              <b-col md="6" class="position-relative">
                <div
                  class="d-none d-lg-flex justify-content-start align-items-start position-absolute"
                  :style="{ left: '0%' }"
                >
                  <div
                    class="vl"
                    :style="{
                      width: '3px',
                      height: '13rem',
                      background: 'white',
                    }"
                  ></div>
                  <div
                    class="hl"
                    :style="{
                      width: '10rem',
                      height: '3px',
                      background: 'white',
                    }"
                  ></div>
                </div>
                <hr class="border-white mt-2 mb-3 d-block d-md-none" />
                <section class="ml-md-5 mt-md-3 pl-3 pl-md-3 pt-md-5">
                  <h1
                    class="text-heading text-left text-white text-24 text-lg-64"
                  >
                    ROST는 <br />
                    대한민국 스쿼시와 <br />
                    함께 합니다.
                  </h1>
                  <ul
                    class="list-unstyled mt-2 mt-md-5 pt-md-3 text-left text-white text-12 text-lg-16"
                    :style="{ opacity: '.6' }"
                  >
                    <li class="mb-2" v-for="(item, i) in contact" :key="i">
                      {{ item.text }}
                    </li>
                    <li class="mt-2 mt-md-4">
                      주소 : 서울특별시 구로구 디지털로 288 대륭포스트타워 1차
                      1212
                    </li>
                  </ul>
                </section>
              </b-col>
              <b-col md="6" class="text-left">
                <b-form @submit.prevent.stop="submit" class="px-3 pb-3 pb-md-5">
                  <header
                    class="mt-3 mt-md-5 text-16 text-lg-24 pb-md-3"
                    :style="{ color: '#999' }"
                  >
                    문의하기
                  </header>
                  <hr
                    class="my-1 mt-md-2 mb-3 mb-md-4"
                    :style="{ borderColor: '#383838' }"
                  />
                  <b-form-input
                    type="text"
                    class="mb-3 mb-md-4"
                    v-model="input.title"
                    placeholder="제목"
                  ></b-form-input>
                  <b-form-input
                    type="email"
                    class="mb-3 mb-md-4"
                    v-model="input.email"
                    placeholder="이메일"
                  ></b-form-input>
                  <b-textarea
                    no-resize
                    type="text"
                    class="mb-3 mb-md-4 d-md-none d-block"
                    v-model="input.desc"
                    rows="3"
                    placeholder="dsec"
                  ></b-textarea>
                  <b-textarea
                    no-resize
                    type="text"
                    class="mb-3 mb-md-4 d-none d-md-block"
                    v-model="input.desc"
                    rows="6"
                    placeholder="내용"
                  ></b-textarea>
                  <b-btn
                    variant="primary"
                    type="submit"
                    class="mb-3 px-4 py-2 text-14"
                    >전송하기</b-btn
                  >
                </b-form>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </section>
    </article>
    <side-btns :sections="sections" :active="active" :options="options" />
  </main>
</template>

<script>
import SideBtns from "../components/SideBtns";
import { Carousel, Slide } from "vue-carousel";
// import CarouselCustomMobile from "../components/CarouselCustomMobile";

export default {
  components: {
    SideBtns,
    Carousel: Carousel,
    CarouselSlide: Slide,
    // CarouselCustomMobile,
  },
  data() {
    return {
      sections: [{ idx: 0 }, { idx: 1 }, { idx: 2 }, { idx: 3 }],
      active: 0,
      options: [
        {
          type: "white",
        },
        {
          type: "white",
        },
        {
          type: "dark",
        },
        {
          type: "white",
        },
      ],
      screenHeight: Number,
      apps: [
        {
          title: "Data",
          icon: "data",
          desc: "모든 데이터를 실시간으로 기록하여 다양한 방법으로 활용",
        },
        {
          title: "Innovation",
          icon: "innovation",
          desc: "맞춤 데이터를 통합 제공하여 해당 연맹 활성화에 기여",
        },
        {
          title: "Publicness",
          icon: "publicness",
          desc: "공신력 있는 연맹들과의 제휴 및 협업으로 공정한 데이터 제공",
        },
      ],
      allInOne: [
        require("@/assets/allinone/slide-0.png"),
        require("@/assets/allinone/slide-1.png"),
        require("@/assets/allinone/slide-2.png"),
        require("@/assets/allinone/slide-3.png"),
        require("@/assets/allinone/slide-4.png"),
      ],
      slideActive: 2,
      contact: [
        {
          text: "업체명 : (주)로스트컴퍼니 | 사업자번호 : 706-86-01270",
        },
        {
          text: "통신판매업등록번호 : 2019-서울서초-1215호",
        },
        {
          text: "전화 : 010-2943-3982 | 이메일 : ROST@rost.kr",
        },
      ],
      input: {
        title: "",
        eamil: "",
        desc: ``,
      },
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.screenHeight = screen.availHeight;
  },
  methods: {
    handleScroll() {
      const y = window.pageYOffset;
      const currentSection = Math.ceil(y / this.screenHeight);

      this.active = currentSection;
    },
    submit() {
      const bool = window.confirm(
        "확인버튼 : 메일이 제대로 보내질 때 || 취소버튼 : 메일기능에 오류가 있을 때 로 확인 가능합니다."
      );
      try {
        if (bool) {
          this.$bvToast.toast(`최대한 빨리 답변드리겠습니다.`, {
            title: "답변이 접수되었습니다!",
            autoHideDelay: 5000,
            variant: "primary",
            toaster: "b-toaster-bottom-center",
          });
        } else if (!bool) {
          this.$bvToast.toast(`아래 연락처를 통해 연락바랍니다 010-`, {
            title: "지금은 이 기능으로 접수를 받을 수 없습니다.",
            autoHideDelay: 5000,
            variant: "error",
            toaster: "b-toaster-bottom-center",
          });
        }
      } catch (error) {
        console.log("------------------------------------");
        console.log(error);
        console.log("------------------------------------");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel-3d-slide {
  @media all and (max-width: 789px) {
    &.left-1,
    &.right-1 {
      width: 240px !important;
    }
    &.left-2,
    &.right-2 {
      width: 150px !important;
    }
    transition: 0.4s ease-in-out !important;

    &.left-1 {
      transform: translateX(-400px) translateY(0px) !important;
    }
    &.left-2 {
      transform: translateX(-400px) translateY(0px) !important;
    }
    &.right-1 {
      transform: translateX(120px) translateY(0px) !important;
    }
    &.right-2 {
      transform: translateX(150px) translateY(0px) !important;
    }
  }
  @media all and (min-width: 790px) {
    &.left-1,
    &.right-1 {
      width: 537px !important;
    }
    &.left-2,
    &.right-2 {
      width: 320px !important;
    }
    transition: 0.4s ease-in-out !important;

    &.left-1 {
      transform: translateX(-320px) translateY(100px) !important;
    }
    &.left-2 {
      transform: translateX(-600px) translateY(150px) !important;
    }
    &.right-1 {
      transform: translateX(600px) translateY(100px) !important;
    }
    &.right-2 {
      transform: translateX(1080px) translateY(150px) !important;
    }
  }
}
</style>
